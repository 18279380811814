import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { appTheme } from './styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { englishTranslation, arabicTranslation } from './utils';
import i18 from 'i18next';
import { initReactI18next } from 'react-i18next';
import './fonts/Cunia/Cunia.otf'
i18.use(initReactI18next).init({
  resources: { en: { translation: englishTranslation }, ab: { translation: arabicTranslation } },
  lng: 'en',
  fallbackLng: 'eng',
  interpolation: { escapeValue: false },
});
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
