export const englishTranslation = {
  selectCountry: 'Select Your Country',
  egypt: 'Egypt',
  sa: 'Saudi Arabia',
  oman: 'Oman',
  uae: 'United Arab Emirates',
  kuwait: 'Kuwait',
  qatar: 'Qatar',
  bahrain: 'Bahrain',
  contact: 'Contact',
  privacy: 'Privacy',
  terms: 'Terms & Privacy Policy',
  website: 'Alain website',
  formHeading: 'Enter Details to Win',
  formHeading2: 'Season Pass!',
  submit: 'Submit',
  city: 'City',
  emirate: 'Emirate',
  email: 'Email',
  receipt: 'Purchase Receipt',
  country: 'Country',
  file: 'Choose File',
  code: 'Code',
  name: 'Name',
  queries: 'For any queries, you may contact us at:',
  copyrights: 'Copyright © Alain | All rights reserved',
  retailer: 'Retailer',
  purchases:'Number Of Purchases',
  product:'Product Photo'
};
