import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  Link as MLink,
  MenuItem,
  Select,
  InputAdornment,
  CircularProgress,
  CircularProgressProps,
  Box,
  styled,
  useTheme,
} from '@material-ui/core';
import { InputField } from '../Form';
import hBG from '../../assets/img/FORM_TOP_NEW_IMAGE_.png';
import hBG2 from '../../assets/img/lain-logo.png';
import { useTranslation } from 'react-i18next';
import countryToCities from '../../assets/others/countryToCities.json';
import retailers from '../../assets/others/retailers.json';
import i18 from 'i18next';
import sand from '../../assets/img/sand.png';
import camera from '../../assets/img/camera.png';
import onlyHat from '../../assets/img/only-hat.png';
import bag from '../../assets/img/Travel_Bag_1.png';
import backgroundImage from '../../assets/img/alain_back_group_.png';
import powered from '../../assets/img/powered.png';
import hBG3 from '../../assets/img/lain-logo-ab.png';
import powered_ab from '../../assets/img/powered-ab.png';
import ball from '../../assets/img/ball.png';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },

  formContainer: {
    padding: '0 4rem 14rem 4rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 4rem 0 4rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 0 0',
    },
  },
  formHeading: {
    textAlign: 'center',
    background: theme.palette.primary.dark,
    padding: '2rem',
    position: 'relative',
    '& img': {
      position: 'absolute',
      maxWidth: '2rem',
      top: 0,
      bottom: 0,
      margin: 'auto',
      left: '2rem',
      cursor: 'pointer',
    },
  },
  form: {
    zIndex: 1,
    padding: '1rem 4rem 4rem 4rem',
    [theme.breakpoints.down('xs')]: {
          padding: '1rem 2rem 4rem 2rem',
    },
    backgroundColor: theme.palette.primary.light,
  },
  userItems: {
    background: theme.palette.primary.light,
  },
  animatedImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    animationName: 'cf3FadeInOut',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationDuration: '5s',
    animationDirection: 'alternate',
  },
  link: {
    color: theme.palette.secondary.main,
  },
  formHeadingContainer: {
    backgroundColor: theme.palette.secondary.light,
    padding: '1.5rem 3rem',
    textAlign: 'center',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    position: 'relative',
    // '&::after': {
    //   content: '""',
    //   position: 'absolute',
    //   // margin: '-20px',
    //   width: '100%',
    //   height: '1rem',
    //   marginTop: '0.8rem',
    //   // transform: 'rotate(45deg)',
    //   backgroundColor: '#fff',
    //   left: 0,
    //   borderRadius: '70% 70% 0% 0%',
    //   backgroundImage: 'linear-gradient(to right, darkgrey 0%, #fff 10% 95%, darkgrey 100% 100%)',
    // },
  },
  sandImage: {
    backgroundImage: `url(${sand})`,
    height: '7rem',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-2rem',
      height: '9rem',
    },
  },
  hatImage: {
    // width:'100%',
    width: '12rem',
    position:'absolute',
    bottom:'-2%',
    right:'-15%',
    display:'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  cameraImage: {
    // width:'100%',
    height: '7rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'absolute',
    left: '15%',
    marginTop: '-2rem',
    zIndex: 6,
    [theme.breakpoints.down('lg')]: {
      height: '8rem',
      marginTop: '-3rem',
      left: '14%',
      // height:'9rem'
      // width:'75%'
    },
    [theme.breakpoints.down('md')]: {
      height: '8rem',
      marginTop: '-3rem',
      left: '1%',
      // height:'9rem'
      // width:'75%'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-2rem',
      left: '1%',
      height: '6rem',
      // height:'9rem'
    },
  },
  bagImage: {
    // width:'100%',
    // height: '10rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'absolute',
    left: '0%',
    zIndex: 5,
    marginLeft: '-8rem',
    height: '20rem',
    top: '70%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: '-8rem',
    //   // left: '14.25%',
    //   height: '20rem',
    //   // height:'9rem'
    //   // width:'75%'
    // },

    // [theme.breakpoints.down('lg')]: {
    //   marginTop: '-20rem',
    //   left: '0%',
    //   height: '20rem',
    //   // height:'9rem'
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginTop: '-20rem',
    //   left: '2%',
    //   height: '20rem',
    //   // height:'9rem'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: '-20rem',
    //   left: '-6%',
    //   height: '20rem',
    //   // height:'9rem'
    // },
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: '-20rem',
    //   left: '-18.5%',
    //   height: '20rem',
    //   // height:'9rem'
    // },
  },
  headerImage:{
      width: '14rem',
      [theme.breakpoints.down('md')]: {
          width: '12rem',
      },
      [theme.breakpoints.down('xs')]: {
          width: '10rem',
      },
  },
  formHeaderImage: {
    width: '50%',
    marginBottom: '-1.50rem',
    [theme.breakpoints.down('md')]: {
          width: '75%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  contact: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));
interface IUProps {
  formik: any;
  progress: number;
  goBack: () => void;
}
const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const UserDetail = (props: IUProps) => {
  const { formik, progress, goBack } = props;
  const classes = useStyles();
  const theme = useTheme();
  // useEffect(() => {
  //   return () => {
  //     formik.resetForm();
  //   };
  // }, []);
  const { t,i18n } = useTranslation();
  const cities: Array<string> = (countryToCities as any)[formik.values.country] || [];
  const _retailers: Array<string> = (retailers as any)['retailer'][formik.values.country] || [];
  return (
    <div className={classes.body}>
      <Box
        style={{
          padding: '0.5rem 0rem 0.5rem 0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img className={classes.headerImage} src={i18n.language==='en'?hBG2:hBG3}/>
        <img className={classes.headerImage} src={i18n.language==='en'?powered:powered_ab} style={{paddingBottom: '2rem' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <img src={hBG} className={classes.formHeaderImage} />
      </Box>
      {/* <div>Alain travel image goes here</div> */}
      <Grid container className={classes.formContainer} justifyContent={'center'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={7}
          xl={7}
          style={{
            margin: '0 -0.2rem',
          }}
        >
          <Box
            sx={{
              // padding: '0 5rem',
              position: 'relative',
              [theme.breakpoints.down('sm')]: {
                // padding: '0 1.5rem 0 1.5rem',
              },
            }}
          >
            <Box className={classes.formHeadingContainer}>
              <Typography
                variant="h3"
                style={{
                  fontSize: '2rem',
                }}
              >
                {t('formHeading')}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={classes.userItems} item xs={12} sm={12} md={10} lg={7} xl={7}>
          {/* <Typography component={'p'} variant={'h2'} className={classes.formHeading}>
            <img onClick={goBack} src={back} />
            {t('formHeading')}
          </Typography> */}
          <form
            className={classes.form}
            onSubmit={formik.handleSubmit}
            style={{
              position: 'relative',
            }}
          >
            <InputField
              textFieldProps={{
                label: t('name'),
                value: formik.values.name,
                variant: 'filled',
                name: 'name',
                fullWidth: true,
                required: true,
                onChange: formik.handleChange,
                // error: formik.touched.name && Boolean(formik.errors.name),
                // helperText: formik.touched.name && formik.errors.name
              }}
            />
            <InputField
              textFieldProps={{
                label: t('email'),
                value: formik.values.email,
                type: 'email',
                name: 'email',
                variant: 'filled',
                fullWidth: true,
                required: true,
                onChange: formik.handleChange,
                // error: formik.touched.email && Boolean(formik.errors.email),
                // helperText: formik.touched.email && formik.errors.email
              }}
            />
            {/* <Select
              value={formik.values.city}
              style={{ margin: '1rem 0' }}
              variant={'outlined'}
              displayEmpty
              name={'city'}
              required
              onChange={formik.handleChange}
              fullWidth
            >
              <MenuItem value={''} disabled>
                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  {t(formik.values.country === 'UAE' ? 'emirate' : 'city')} *
                </span>
              </MenuItem>
              {cities.map((item: string, index: number) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            {/* <PhoneInputWithCountrySelect
              international
              defaultCountry="US"
              onChange={() => {}}
              value=""
              focusInputOnCountrySelection={false}
              countrySelectProps={{
                style: {
                  backgroundColor: 'black',
                },
              }}
              inputComponent={() => (
                <InputField
                  textFieldProps={{
                    variant: 'outlined',
                    fullWidth: true,
                  }}
                />
              )}
            /> */}
            <InputField
              textFieldProps={{
                label: t('contact'),
                value: formik.values.mobileNumber,
                name: 'mobileNumber',
                variant: 'filled',
                fullWidth: true,
                required: true,
                type: 'number',
                InputProps: {
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ maxWidth: '2rem' }} src={formik.values.flag} />
                        {formik.values.code}
                      </span>
                    </InputAdornment>
                  ),
                },
                onChange: formik.handleChange,
                // error: formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber),
                // helperText: formik.touched.mobileNumber && formik.errors.mobileNumber
              }}
            />
            <Select
              value={formik.values.city}
              style={{ margin: '0.5rem 0' }}
              variant={'outlined'}
              displayEmpty
              name={'city'}
              required
              onChange={formik.handleChange}
              fullWidth
              label={formik.values.country === 'UAE' ? "Emirate": 'Cities'}
            >
              <MenuItem value={''} disabled>
                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{formik.values.country === 'UAE' ? t('emirate') : t('cities')} *</span>
              </MenuItem>
              {cities.map((item: string, index: number) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={formik.values.retailer}
              style={{ margin: '0.5rem 0' }}
              variant={'outlined'}
              displayEmpty
              name={'retailer'}
              required
              onChange={formik.handleChange}
              fullWidth
              label="Retailer"
            >
              <MenuItem value={''} disabled>
                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{t('retailer')} *</span>
              </MenuItem>
              {_retailers.map((item: string, index: number) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {formik.values.retailer.toLocaleLowerCase() === 'other' && (
              <InputField
                textFieldProps={{
                  label: t('retailer name'),
                  value: formik.values.retailerOther,
                  variant: 'filled',
                  name: 'retailerOther',
                  fullWidth: true,
                  required: true,
                  onChange: formik.handleChange,
                  // error: formik.touched.name && Boolean(formik.errors.name),
                  // helperText: formik.touched.name && formik.errors.name
                }}
              />
            )}
            <InputField
              textFieldProps={{
                label: `${t('product')} *`,
                value: formik.values.productFile?.name || '',
                name: 'productFile',
                disabled: true,
                variant: 'filled',
                fullWidth: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <label htmlFor={'productFile'}>
                        <input
                          type={'file'}
                          id={'productFile'}
                          name={'productFile'}
                          accept={'image/*'}
                          onChange={(event: any) => {
                            formik.setFieldValue(
                              'productFile',
                              event.currentTarget.files[0] || null,
                            );
                          }}
                          style={{ display: 'none' }}
                        />
                        <Button color={'primary'} variant={'contained'} component={'span'}>
                          + {t('file')}
                        </Button>
                      </label>
                    </InputAdornment>
                  ),
                },
                error: formik.touched.file && Boolean(formik.errors.file),
                helperText: formik.touched.file && formik.errors.file,
              }}
            />
            <InputField
              textFieldProps={{
                label: `${t('receipt')} *`,
                value: formik.values.receiptFile?.name || '',
                name: 'receiptFile',
                disabled: true,
                variant: 'filled',
                fullWidth: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <label htmlFor={'receiptFile'}>
                        <input
                          type={'file'}
                          id={'receiptFile'}
                          name={'receiptFile'}
                          accept={'image/*'}
                          onChange={(event: any) => {
                            formik.setFieldValue(
                              'receiptFile',
                              event.currentTarget.files[0] || null,
                            );
                          }}
                          style={{ display: 'none' }}
                        />
                        <Button color={'primary'} variant={'contained'} component={'span'}>
                          + {t('file')}
                        </Button>
                      </label>
                    </InputAdornment>
                  ),
                },
                error: formik.touched.file && Boolean(formik.errors.file),
                helperText: formik.touched.file && formik.errors.file,
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span
                style={{
                  color: 'red',
                }}
              >
                *
              </span>{' '}
              Only image formats allowed
            </div>
            <Select
              value={formik.values.numberOfPurchases}
              style={{ margin: '0.5rem 0' }}
              variant={'outlined'}
              displayEmpty
              name={'numberOfPurchases'}
              required
              onChange={formik.handleChange}
              fullWidth
              label=""
            >
              <MenuItem value={''} disabled>
                <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{t('purchases')} *</span>
              </MenuItem>
              {(Array.from({ length: 10 }, (_, i) => i + 1) as any).map(
                (item: string, index: number) => (
                  <MenuItem key={`${item}-${index}`} value={item}>
                    {item}
                  </MenuItem>
                ),
              )}
            </Select>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <span
                style={{
                  color: 'red',
                }}
              >
                *
              </span>{' '}
              Please enter the same number as shown on the receipt
            </div>
            <FormControlLabel
              style={{ marginTop: '4rem' }}
              control={<Checkbox required={true} color={'secondary'} />}
              label={
                <span>
                  I agree to all{' '}
                    <Link className={classes.link} to={`/terms/${formik.values.country}`} target={'_blank'}>Terms & Conditions</Link>
                </span>
              }
            />
            <Button
              color="primary"
              style={{ margin: '0.5rem 0', backgroundColor: theme.palette.secondary.dark }}
              variant="contained"
              disabled={formik.isSubmitting}
              fullWidth
              type="submit"
            >
              {progress ? (
                <CircularProgressWithLabel
                  color={'secondary'}
                  variant={'determinate'}
                  value={progress}
                />
              ) : (
                t('submit')
              )}
            </Button>

            <Typography
              style={{ textAlign: 'center' }}
              variant={'subtitle2'}
              component={'p'}
              className={classes.contact}
            >
              {t('queries')}
              <br />
              <MLink href={'mailto:contact@winwithalainwater.com'} target={'_blank'} color={'secondary'}>
                contact@winwithalainwater.com
              </MLink>
            </Typography>
            <img src={ball} className={classes.hatImage} />
          </form>
        </Grid>
      </Grid>
    </div>
  );
};
