import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  frame: {
    width: '100%',
    height: '99%',
    border: 'none',
  },
}));

export const TermsPage = () => {
  const classes = useStyles();
  const { country } = useParams<any>();
  return (
    <div style={{
      backgroundColor:'white',
      height:'100%'
    }} >
      <iframe className={classes.frame} src={`./${country}.html`} />
    </div>
  );
};
