import { createMuiTheme, ThemeOptions } from '@material-ui/core';
export const appTheme: ThemeOptions = createMuiTheme({
  palette: {
    primary: {
      main: '#1B3C69',
      light: '#FFFFFF',
      dark: '#1B3C69',
    },
    secondary: {
      main: '#0072BC',
      light:'#EC1846',
      dark:'#1F4F7D'
    },
  },
  typography: {
    fontFamily: 'cunia',
    h1: {
      color: '#fff',
      fontSize: '2.5rem',
      // fontFamily: 'hariboFontBold',
    },
    h2: {
      color: '#F7B93C',
      fontSize: '2.2rem',
      // fontFamily: 'hariboFontBold',
    },
    h3: {
      color: '#fff',
      fontSize: '0.9rem',
      // fontFamily: 'hariboFontBold',
    },
    h4: {
      color: '#fff',
      fontSize: '0.9rem',
    },
    subtitle1: {
      color: '#fff',
      fontSize: '1.2rem',
      // fontFamily: 'hariboFontBold',
    },
    subtitle2: {
      color: '#000',
      fontSize: '1rem',
    },
    caption: {
      color: '#000',
      fontSize: '0.8rem',
    },
  },
  breakpoints: {},
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '@media (max-width: 80px)': {
            fontSize: '8px',
          },
          '@media (max-width: 1000px)': {
            fontSize: '12px',
          },

          '@media (min-width: 2048px)': {
            fontSize: '20px',
          },
        },
      },
    },

    MuiButton: {
      root: {
        height: '3.2rem',
        textTransform: 'capitalize',
        // borderRadius:'120px'
      },
      containedPrimary: {
        backgroundColor: '#AEB3C1',
        color: 'white',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: '#AEB3C1',
          opacity: '0.8',
        },
      },
    },
    MuiTextField: {
      root: {
        margin: '1rem 0',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1rem',
        fontWeight: 'normal',
        '&.Mui-focused:not(.Mui-error)': {
          color: '#0072BC',
        },
        '& + .MuiInput-formControl': {},
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '0.2rem',
      },
    },
    MuiInputBase: {
      root: {
        background: '#EAF2FB',
        // borderRadius:'30px !important'
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#EAF2FB',
        '&:hover,&:focus': {
          backgroundColor: 'rgba(234, 242, 251, 0.5)',
        },
        '&.Mui-disabled': {
          backgroundColor: '#EAF2FB',
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
      underline: {
        '&.Mui-disabled:before': {
          borderBottom: 'none',
        },
        '&:after,&:before,&:hover:before': {
          borderBottom: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: 'none',
      },
    },
  },
});
