import axios from 'axios';

const url = 'https://61bzxtjhh4.execute-api.us-east-2.amazonaws.com/prod' as const;

export const addUser = (body: any) =>
  axios.post(`${url}/user`, {
    ...body,
  });
export const getPresignedUrl = () => axios.get(`${url}/user/presigned`);

export const getUserPrize = () => axios.get(`${url}/user/prize`);

export const getUserPrizes = (purchases: string, country: string) =>
  axios.get(`${url}/user/prize`, {
    params: {
      purchases,
      country
    }
  });
