export const arabicTranslation = {
  selectCountry: 'اختر بلدك',
  egypt: 'مصر',
  sa: 'المملكة العربية السعودية',
  oman: 'سلطنة عمان',
  uae: 'الإمارات العربية المتحدة',
  kuwait: 'الكويت',
  qatar: 'دولة قطر',
  bahrain: 'البحرين',
  contact: 'اتصل',
  privacy: 'خصوصية',
  terms: 'الشروط وسياسة الخصوصية',
  website: 'موقع هاريبو',
  formHeading: 'أدخل التفاصيل للفوز',
  formHeading2: 'مرور الموسم!',
  submit: 'إرسال',
  city: 'مدينة',
  emirate: 'الإمارة',
  email: 'البريد الإلكتروني',
  receipt: 'إيصال الشرا',
  country: 'بلد',
  file: 'اختر ملف',
  code: 'الشفرة',
  name: 'اسم',
  queries: ':لأية استفسارات ، يمكنك الاتصال بنا على',
  copyrights: 'حقوق النشر © Alain | كل الحقوق محفوظة',
  retailer: 'بائع تجزئة',
  purchases:'عدد المشتريات',
  product:'صورة المنتج',
  //CITIES
  "Muscat" : "مسقط",
  "Salalah" :"صلالة",
  "Ruwi" :"روي",
  "Al Sohar": "صحار",
  "Nizwa" : "نزوى",
  "Samad" :"صمد",
  "Saham": "صحم",
  "Cairo" :"القاهرة",
  "New Cairo":"القاهرة الجديدة",
  "Alexandria": "الإسكندرية",
  "Giza":"الجيزة",
  "Helwan":"حلوان",
  "Shubra":"شبرا",
  "Damietta":"دمياط",
  "Suez":"السويس",
  "Madinat an Nasr":"مدينة نصر",
  "Al Mansurah":"المنصورة",
  "Bani Suwayf":"بني سويف",
  "Sohag":"سوهاج",
  "Tanta":"طنطا",
  "Zagazig":"الزقازيق",
  "Ramsis":"رمسيس" ,
  "Al Mahallah al Kubra": "المحلة الكبرى",
  "Heliopolis":  "مصر الجديدة",
  "Hurghada":  "الغردقة",
  "Az Zamalik": "الزمالك",
  "Rafah": "رفح",
  "Madinat as Sadis min Uktubar":"مدينة السادس من أكتوبر" ,
  "Ismailia":  "الإسماعيلية",
  "Faraskur": "فارسكور",
  "Shirbin": "شربين",
  "Toukh":  "طوخ",
  "Banha": "بنها",
  "Port Said":"بورسعيد",
  "Luxor":  "الأقصر",
  "Minya":"المنيا",
  "Sadat": "السادات" ,
  "Almazah":"الماظة",
  "Assiut":"أسيوط",
  "Al Marj": "المرج",
  "Abu Hammad": "أبو حماد",
  "Nasr":  " نصر",
  "Flaminj": "فلامينج" ,
  "Kafr ash Shaykh":"كفر الشيخ",
  "Badr" :"بدر",
  "Dubai":"دبي",
  "Sharjah":"الشارقة",
  "Ajman": "عجمان" ,
  "Abu Dhabi":"أبو ظبي",
  "Al Ain":"العين" ,
  "Fujairah":"الفجيرة",
  "Ras Al Khaimah": "رأس الخيمة",
  "Umm Al Quwain" :  "أم القيوين",
  "Kuwait City":"مدينة الكويت",
  "Bayan":"بيان",
  "Al Farwaniyah":"الفروانية",
  "Hawalli": "حولي",
  "Ar Rawdah": "الروضة",
  "As Salimiyah":"السالمية",
  "Salwa":"سلوى",
  "Al Shamiya":"الشامية",
  "Kayfan": "كيفان",
  "Al Ahmadi":"الأحمدي",
  "Abraq Khaytan": "أبرق خيطان",
  "Ash Shuwaykh": "الشويخ",
  "Janub as Surrah":"جنوب السرة",
  "Ad Dasmah":"الدسمة",
  "Ad Dawhah":"الدوحة",
  "Ash Shu`aybah":"الشعيبة",
  "Doha":"الدوحة",
  "Al Wakrah": "الوكرة",
  "Ar Rayyan":"الريان",
  "Manama":"المنامة",
  "Barbar":"بربر",
  "Al Muharraq": "المحرق",
  "Madinat Hamad":"مدينة حمد",
  "Al Janabiyah":"الجنبية",
  "Al Markh":"المرخ",
  "Al Budayyi`":"البديع",
  "Bani Jamrah":"بني جمرة",
  "Sanad":"سند",
  "Madinat `Isa":"مدينة عيسى",
  "Al Hadd":"الحد",
  "Al Hamalah": "الهملة",
  "Sitrah":"سترة",
  "Tubli":"توبلي",
  "Jurdab":"جرداب",
  "Oil City": "المدينة النفطية",
  "Sanabis":"السنابس"
};
