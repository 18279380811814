import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  Box,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Country, PrizeWheel, UserDetail } from '../../components';
import { useTranslation } from 'react-i18next';
import i18 from 'i18next';
import { addUser, getPresignedUrl, getUserPrizes } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import Marquee from "react-fast-marquee";


const useStyles = makeStyles((theme) => ({
  parent: {
    padding: 0,
    height: '100%',
    maxWidth: '1400px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    position: 'relative',
  },
  footer: {
    background: theme.palette.primary.light,
    padding: '1.5rem 1.5rem 0 1.5rem',
  },
  footerUnderline: {
    background: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  userItems: {
    padding: '1rem',
  },
  languageSelect: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    '& .MuiSelect-outlined': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));
const addUserSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  mobileNumber: Yup.string().required('Required'),
  // emirate: Yup.string().required('Required'),
  retailer: Yup.string().required('Required'),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    .required('Required'),
  productFile: Yup.mixed().required('Product is required'),
  receiptFile: Yup.mixed().required('Receipt is required'),
  numberOfPurchases: Yup.number().required(),
});

export const LandingPage = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setSubmittingStatus] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
  };

  const submitForm = async (prizes: any) => {
    try {
      setSubmittingStatus(true);
      const values = formik.values;
      values.prizes = prizes && prizes.length > 0 ? prizes : formik.values.prizes;
      const payload: any = { ...values };
      payload.mobileNumber = payload.code + payload.mobileNumber;
      delete payload.code;
      delete payload.flag;

      if (payload?.retailer?.toLocaleLowerCase() === 'other') {
        payload.retailer = payload.retailerOther;
      }

      delete payload.retailerOther;

      const config = {
        onUploadProgress: (progressEvent: any) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            setProgress(Math.round((progressEvent.loaded * 100) / totalLength));
          }
        },
      };
      const signedUrlResp = await getPresignedUrl();
      const { presignedUrl } = signedUrlResp.data.body;
      await axios.put(presignedUrl, values.productFile, {
        headers: { 'Content-Type': (values as any).productFile.type },
        ...config,
      });
      payload.product = presignedUrl.split('?')[0];
      delete payload.productFile;

      const signedUrlRespReceipt = await getPresignedUrl();
      const { presignedUrl: receiptSignedUrl } = signedUrlRespReceipt.data.body;
      await axios.put(receiptSignedUrl, values.receiptFile, {
        headers: { 'Content-Type': (values as any).receiptFile.type },
        ...config,
      });
      payload.receipt = receiptSignedUrl.split('?')[0];
      delete payload.receiptFile;

      payload.purchases = payload.numberOfPurchases;
      delete payload.numberOfPurchases;

      const resp = await addUser(payload);
      setProgress(0);
      setSubmittingStatus(false);
      if (resp.status === 200) {
        if (resp.data.statusCode === 200) {
          handleClickOpen();
          return;
        }

        toast(resp.data.body.message, { type: 'info' });
      }
    } catch (e) {
      setProgress(0);
      toast('Internal Server Error', { type: 'error' });
      setSubmittingStatus(false);
      console.error(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobileNumber: '',
      city: '',
      retailer: '',
      retailerOther: '',
      productFile: null,
      receiptFile: null,
      numberOfPurchases: '',
      country: '',
      code: '',
      flag: '',
      prizes: [],
      // flag: null,
    },
    onSubmit: async (values) => {
      const {
        data: { prizes },
      } = await getUserPrizes(values.numberOfPurchases as any, values.country);
        formik.setFieldValue('prizes', prizes);
        setActiveStep(2);
    },
    validationSchema: addUserSchema,
    enableReinitialize: false,
  });

  const handleCountryChange = (name: string, code: string, icon: any) => {
    formik.setFieldValue('country', name);
    formik.setFieldValue('code', code);
    formik.setFieldValue('flag', icon);
    setActiveStep(activeStep + 1);
  };
  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
  }, [activeStep]);

  const steps: Array<React.ReactElement> = [
    <Country handleClick={handleCountryChange} />,
    <UserDetail progress={progress} formik={formik} goBack={() => setActiveStep(0)} />,
    <PrizeWheel formik={formik} submitForm={submitForm} />,
  ];
  return (
    <Container component={'div'} className={classes.parent} maxWidth={'xl'}>
      <Marquee style={{background:'red',color:'white'}}>
        The competition is now closed. Any purchases after 30th June will be invalid.
      </Marquee>
      <div className={classes.container}>
        <Select
          value={i18.language}
          variant={'outlined'}
          className={classes.languageSelect}
          onChange={(event: any) => {
            i18.changeLanguage(event.target.value);
          }}
        >
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'ab'}>Arabic</MenuItem>
        </Select>
        {steps[activeStep]}
        <div className={classes.footerUnderline}>
          <Typography
            component={'p'}
            variant={'h3'}
            style={{
              fontSize: '0.75rem',
            }}
          >
            {t('copyrights')}
          </Typography>
          <Typography component={'p'} variant={'h3'}>
            {activeStep !== 0 && (
                <Link style={{
                  color: 'white',
                  fontSize: '0.75rem',
                }}
                      to={`/terms/${formik.values.country}`} target={'_blank'}>{t('terms')}</Link>
            )}
          </Typography>
        </div>
      </div>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Your Cricket Fever has been Unleashed!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you! your entry has been submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        style={{ color: '#fff', zIndex: theme.zIndex.drawer + 1,textAlign:'center',fontSize:'1.5rem',display:'flex',flexDirection:'column' }}
        open={isSubmitting}
      >
        <Typography component={'p'}>Please wait while we submit your entry.<br/>Do not close this tab.<br/>Thank you for your patience!</Typography>
        <div><CircularProgress color="inherit" /></div>
      </Backdrop>
    </Container>
  );
};
