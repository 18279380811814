import {Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core"
import React from "react";

interface Props {
  open: boolean
  handleClose: ()=>void,
  prizeTitle:string
  tries: number
}

export const PrizeModal = ({open, handleClose, prizeTitle, tries}: Props) => {
  if(!tries)
    setTimeout(()=>{
    handleClose();
    },2000)

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    disableBackdropClick
  >
    <DialogTitle id="alert-dialog-title">CONGRATULATIONS!</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        YOU WON <b>{prizeTitle}</b><br /><br />
       {tries ?  `You have ${tries} more ${tries === 1 ? 'try' : 'tries'}` : ''}
       {!tries && 'Please wait entry submission will start in a while, check your spam after entry submission so you don\'t miss out on gaining the ultimate happiness'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {tries ? <Button onClick={handleClose}>
        Spin Again!
      </Button> : null }
    </DialogActions>
  </Dialog>
  )
}
