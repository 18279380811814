import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import Confetti from 'react-confetti';
import { useMeasure } from 'react-use';
import { useEffect, useMemo, useRef, useState } from 'react';
import WheelComponent from './Temp';
import lainlogo from '../../assets/img/prize_count.png';
import bgImage from '../../assets/img/BGAsset_44x.png';
import hBG2 from '../../assets/img/lain-logo.png';
import powered from '../../assets/img/powered.png';
import { PrizeModal } from '../PrizeModal';
import backgroundImage from '../../assets/img/alain_back_group_.png';
import wheelBoundary from '../../assets/img/new_new_white_boundary_centered.png';
import BigWheelComponent from './BigWheel';
import { countryPrizes } from './countryPrizes';
import powered_ab from "../../assets/img/powered-ab.png";
import hBG3 from '../../assets/img/lain-logo-ab.png';
import {useTranslation} from "react-i18next";

interface Props {
  formik: any;
  submitForm: (arg: any) => void;
}

export const PrizeWheel = ({ formik, submitForm }: Props) => {
  const [count, setCount] = useState(formik.values.numberOfPurchases);
  const getPrize = useRef(0);
  const {i18n} = useTranslation();
  // const [segments, setSegments] = useState(['A', 'B', 'C', 'A', 'B', 'A', 'A', 'C', 'D'])
  // const [prizes, setPrizes] = useState<string[]>([]);
  const prizes = useRef<string[]>([]);
  const [shouldNotSpin, setShouldNotSpin] = useState<boolean>(false);
  const [firstTime, setFirstTime] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const [currentPrize, setCurrentPrize] = useState<string>('');
  const [open, setOpen] = useState(false);
  const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const prizeList = {
    A: 'Holiday Package',
    B: 'Airline Ticket',
    C: 'Digital Poster',
    D: 'Starzplay Entertainment',
    E: 'Personalized Jersey',
    F: 'Starzplay Cricket'
  };

  const getRandomItem = (arr: number[]) => {
    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);

    // get random item
    const item = arr[randomIndex];

    return item;
  };

  const getRandomSegmentNumber = (segmentClass: 'A' | 'B' | 'C' | 'D' | 'E'| 'F' ) => {
    if (segmentClass === 'A'){
      return 1;
    } else if (segmentClass === 'B') {
      return 7
    } else if (segmentClass === 'C') {
      return getRandomItem([2 ,  6,  8]);
    }
    else if (segmentClass === 'E') {
      return 4;
    }
    else if (segmentClass === 'F'){
      return 5;
    }
    return 3;
  };

  // Vars used by the code in this page to do power controls.
  let wheelSpinning = false;
  let wheelPower = 0;

  function alertPrize(indicatedSegment: any) {
    // Do basic alert of the segment text. You would probably want to do something more interesting with this information.
    // alert('You have won ' + indicatedSegment.text);
    shouldSubmit(formik.values.prizes[getPrize.current]);
    // resetWheel();
    // wheelSpinning = false;
    // shouldSubmit('C');
  }

  // -------------------------------------------------------
  // Click handler for spin button.
  // -------------------------------------------------------
  function startSpin() {
    // Ensure that spinning can't be clicked again while already running.
    if (wheelSpinning == false) {
      if (shouldNotSpin) return;
      // Based on the power level selected adjust the number of spins for the wheel, the more times is has
      // to rotate with the duration of the animation the quicker the wheel spins.
      setCount(count - 1);
      theWheel.animation.spins = 8;
      // if (wheelPower == 1) {
      //   theWheel.animation.spins = 3;
      // } else if (wheelPower == 2) {
      // } else if (wheelPower == 3) {
      //   theWheel.animation.spins = 15;
      // }
      // console.log(theWheel.animation.spins)
      // console.log(
      //   segmentMapping[formik.values.prizes[getPrize.current]],
      //   formik.values,
      //   getPrize.current,
      // );
      const stopAt = theWheel?.getRandomForSegment(
        // segmentMapping[formik.values.prizes[getPrize.current]],
        getRandomSegmentNumber(formik.values.prizes[getPrize.current]),
      );
      // getPrize.current += 1;
      theWheel.animation.stopAngle = stopAt;

      // Begin the spin animation by calling startAnimation on the wheel object.
      theWheel.startAnimation();

      // Set to true so that power can't be changed and spin button re-enabled during
      // the current animation. The user will have to reset before spinning again.
      wheelSpinning = true;
    }
  }

  // -------------------------------------------------------
  // Function for reset button.
  // -------------------------------------------------------
  function resetWheel() {
    theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
    theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
    theWheel.draw(); // Call draw to render changes to the wheel.

    wheelSpinning = false; // Reset to false to power buttons and spin can be clicked again.
  }

  // Create new wheel object specifying the parameters at creation time.
  let theWheel: any = new Winwheel({
    numSegments: 8, // Specify number of segments.
    outerRadius: !isBigScreen?170:210, // Set outer radius so wheel fits inside the background.
    textFontSize: !isBigScreen?8:11, // Set font size as desired.
    lineWidth: 1,
    textFontFamily: 'Cunia',
    innerRadius: 20,
    // drawMode: 'image',
    // Define segments including colour and text.
    // [2,4,6,8] - C
    // [1] - A
    // [3,5] - B
    // [7] - D
    segments: countryPrizes[formik.values.country],
    // Specify the animation to use.
    animation: {
      type: 'spinToStop',
      duration: 5, // Duration in seconds.
      spins: 8, // Number of complete spins.
      callbackFinished: alertPrize,
    },
  });

  // Create new image object in memory.
  // let loadedImg = new Image();

  // // Create callback to execute once the image has finished loading.
  // loadedImg.onload = function () {
  //   theWheel.wheelImage = loadedImg; // Make wheelImage equal the loaded image object.
  //   theWheel.draw(); // Also call draw function to render the wheel.
  // };

  // // Set the image source, once complete this will trigger the onLoad callback (above).
  // loadedImg.src = '../../assets/img/new_white_wheel_boundary.png';

  useEffect(() => {
    setTimeout(() => {
      setToggle(true);
    }, 100);
  }, []);

  const onFinished = (winner: any) => {
    console.log(winner);
  };

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  const refreshChart = () => {
    setToggle(false);
    setTimeout(() => {
      setToggle(true);
      document?.getElementById('canvas')?.click();
    }, 1000);
  };

  useEffect(() => {
    // open && refreshChart();
    // setToggle(false);
    if (!firstTime) {
      if (!open) {
        if (getPrize.current === formik.values.prizes.length - 1) {
          submitForm(prizes.current);
        } else {
          getPrize.current += 1; //refreshChart();
          setFirstTime(false);
          startSpin();
          setShouldNotSpin(true);
          // document?.getElementById('canvas')?.click();
        }
      }
    }
    setFirstTime(false);
  }, [open]);

  const shouldSubmit = (winner: 'A' | 'B' | 'C' | 'D' ) => {
    // setShouldNotSpin(true);
    // prizes.current.push(winner);
    // let _s = segments
    // _s[5] = winner
    // setSegments(_s)
    // if (prizes.current.length === formik.values.numberOfPurchases) {
    //   submitForm(prizes.current);
    // } else {
    setTimeout(() => {
      setCurrentPrize(prizeList[winner]);
      setOpen(true);
      setShouldNotSpin(false);
      resetWheel();
    }, 1000);
    // }
  };

  const handleClose = () => {
    setCurrentPrize('');
    setOpen(false);
    // resetWheel()
  };

  return (
    <Box
      style={{
        flexGrow: 1,
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        style={{
          padding: '0.5rem 0rem 0.5rem 0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={i18n.language==='en'?hBG2:hBG3} style={{ maxWidth: '50%', width: '14rem' }} />
        <img src={i18n.language==='en'?powered:powered_ab} style={{ maxWidth: '50%', width: '16rem', paddingBottom: '2rem' }} />
      </Box>
      <div
        ref={ref}
        style={{
          padding: '1rem',
          display: 'flex',
          justifyContent: 'center',
          // position:'relative',
          height: '9rem',
          paddingBottom: '2rem',
          // marginLeft: `-${(count / 2) * 1.25}rem`,
        }}
      >
        {open && <Confetti width={width} />}

        {Array.from({ length: count >= 5 ? 5 : count }).map((_, i: number) => {
          return (
            <div
              style={
                {
                  // paddingLeft: `0.25rem`,
                  // position: 'absolute',
                }
              }
            >
              <img src={lainlogo} alt="logo" style={{margin:"0 0.5rem"}} height={!isBigScreen ? '40px' : '80px'} />
            </div>
          );
        })}
      </div>
      {count > 5 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            // position:'relative',
            height: isBigScreen ? '7rem' : '7rem',
            // marginLeft: `-${(count / 2) * 1.25}rem`,
          }}
        >
          {Array.from({ length: count > 5 ? count - 5 : 0 }).map((_, i: number) => {
            return (
              <div
                style={{
                  paddingLeft: `0.25remrem`,
                  // position: 'absolute',
                }}
              >
                <img src={lainlogo} alt="logo" style={{margin:"0 0.5rem"}} height={!isBigScreen ? '40px' : '80px'} />
              </div>
            );
          })}
        </div>
      )}
      {/* {!toggle && (
        <div
          style={{
            textAlign: 'center',
            padding: '3rem',
            color: '#f3f3f3',
          }}
        >
          Loading Spin wheel for next round...
        </div>
      )} */}
      {/* <div>
        <a
          href="#"
          onClick={() => {
            resetWheel();
            return false;
          }}
        >
          Play Again
        </a>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(reset)
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <canvas id="canvas" width={!isBigScreen?"350":"434"} height={!isBigScreen?"350":"434"}>
          <p style={{ color: 'white' }}>
            Sorry, your browser doesn't support canvas. Please try another.
          </p>
        </canvas>
        <div
          style={{
            position: 'absolute',
            backgroundImage: `url(${wheelBoundary})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            height: '100%',
            width: !isBigScreen?"350px":"434px",
          }}
        ></div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '3rem',
        }}
      >
        <Button
          style={{
            backgroundColor: '#CD2E3B',
            fontSize: '1.5rem',
            padding: '0.5rem 3rem',
            color: '#f3f3f3',
          }}
          onClick={() => {
            startSpin();
            setShouldNotSpin(true);
          }}
        >
          Spin!
        </Button>
      </div>
      {/* {isBigScreen && toggle && (
        <BigWheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment=""
          onFinished={(winner: any) => shouldSubmit(winner)}
          primaryColor="darkred"
          // primaryColoraround="#ffffffb4"
          contrastColor={'yellow'}
          buttonText="Go!"
          isOnlyOnce={false}
          size={275}
          upDuration={500}
          downDuration={1000}
          formik={formik}
          submitForm={submitForm}
          setCount={setCount}
          count={count}
          prizeList={userPrizeList}
          fontFamily="'Cunia'"
          shouldNotSpin={shouldNotSpin}
        />
      )}
      {!isBigScreen && toggle && (
        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment=""
          onFinished={(winner: any) => shouldSubmit(winner)}
          primaryColor="darkred"
          // primaryColoraround="#ffffffb4"
          contrastColor={'yellow'}
          buttonText="Go!"
          isOnlyOnce={false}
          size={150}
          upDuration={500}
          downDuration={1000}
          formik={formik}
          submitForm={submitForm}
          setCount={setCount}
          count={count}
          prizeList={userPrizeList}
          fontFamily="'Cunia'"
          shouldNotSpin={shouldNotSpin}
        />
      )} */}
      {open && (
        <PrizeModal open={open} handleClose={handleClose} prizeTitle={currentPrize} tries={count} />
      )}
    </Box>
  );
};
