export const countryPrizes: any = {
  UAE: [
    {
      fillStyle: '#13A5D8',
      text: 'Holiday Package',
      textFillStyle: '#f2f2f2',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#FFFFFF',
      text: 'Digital Poster',
      textFillStyle: '#000000',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#D4243B',
      text: 'Starzplay Entertainment',
      textFillStyle: '#f2f2f2',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#FFFFFF',
      text: 'Personalized Jersey',
      textFillStyle: '#000000',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#13A5D8',
      text: 'Starzplay Cricket',
      textFillStyle: '#f2f2f2',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#D4243B',
      text: 'Digital Poster',
      textFillStyle: '#f2f2f2',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#13A5D8',
      text: 'Airline Ticket',
      textFillStyle: '#f2f2f2',
      strokeStyle: 'transparent',
    },
    {
      fillStyle: '#FFFFFF',
      text: 'Digital Poster',
      textFillStyle: '#000000',
      strokeStyle: 'transparent',
    },
  ]
};
