import React from 'react';
import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  uploadFile: {
    margin: '0.5rem 0',
    '& .MuiButtonBase-root': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

interface IProps {
  textFieldProps: TextFieldProps;
}

export const InputField = (props: IProps) => {
  const { textFieldProps } = props;
  const classes = useStyles();
  return (
    <>
      <TextField
        className={classes.uploadFile}
        {...textFieldProps}
        InputProps={{
          ...textFieldProps.InputProps,
          style: {
            borderRadius: '0',
           
          },
        }}
      />
    </>
  );
};
