import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import arab from '../../assets/img/arab.png';
import oman from '../../assets/img/oman.png';
import kuwait from '../../assets/img/kuwait.png';
import qatar from '../../assets/img/qatar.png';
import sa from '../../assets/img/sa.png';
import bahrain from '../../assets/img/bahrain.png';
import hBG from '../../assets/pringles/header.png';
import i18 from 'i18next';
import { useTranslation } from 'react-i18next';
import vacationAlain from '../../assets/img/drop.png';
import backgroundImage from '../../assets/img/alain_back_group_2.png';
import hBG2 from '../../assets/img/lain-logo.png';
import powered from '../../assets/img/powered.png';
import hBG3 from '../../assets/img/lain-logo-ab.png';
import powered_ab from '../../assets/img/powered-ab.png';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    flex: 1,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  formContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {},
  },
    headerImage:{
        width: '14rem',
        [theme.breakpoints.down('md')]: {
            width: '12rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: '10rem',
        },
    },
  countryItems: {
    textAlign: 'center',
    '& img': {
      height: 'auto',
      maxWidth: '100%',
      opacity: '1',
      webkitTransition: '.3s ease-in-out',
      transition: '.3s ease-in-out',
      '&:hover': {
        cursor: 'pointer',
        opacity: '.5',
      },
    },
  },
}));
interface IProps {
  handleClick: (name: string, code: string, icon: any) => void;
}
export const Country = (props: IProps) => {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const { handleClick } = props;
  return (
    <div className={classes.body}>
      <Box
        style={{
          padding: '0.5rem 0rem 0.5rem 0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img src={i18n.language==='en'?hBG2:hBG3} className={classes.headerImage} />
        <img src={i18n.language==='en'?powered:powered_ab} className={classes.headerImage} style={{ paddingBottom: '3rem' }} />
      </Box>
      <div className={classes.header}>
        <img src={vacationAlain} style={{ height: '38rem',marginBottom:"2rem" }} />
      </div>
      <Typography style={{ textAlign: 'center', color: 'white' }} component={'p'} variant={'h2'}>
        {t('selectCountry')}
      </Typography>
      <Grid
        container
        className={classes.formContainer}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>
          <img
            src={arab}
            style={{ height: '9rem' }}
            onClick={() => handleClick('UAE', '+971', arab)}
          />
          <Typography component={'p'} variant={'subtitle1'}>
            {t('uae')}
          </Typography>
        </Grid>
        {/* <Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>
            <img src={sa} onClick={() => handleClick('Saudi', '+966', sa)} />
            <Typography component={'p'} variant={'subtitle1'}>
              {t('sa')}
            </Typography>
          </Grid> */}
        {/* <Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>
            <img src={oman} onClick={() => handleClick('Oman', '+968', oman)} />
            <Typography component={'p'} variant={'subtitle1'}>
              {t('oman')}
            </Typography>
          </Grid> */}
        {/*<Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>*/}
        {/*  <img*/}
        {/*    src={kuwait}*/}
        {/*    style={{*/}
        {/*      height: '9.2rem',*/}
        {/*    }}*/}
        {/*    onClick={() => handleClick('Kuwait', '+965', kuwait)}*/}
        {/*  />*/}
        {/*  <Typography component={'p'} variant={'subtitle1'} style={{*/}
        {/*    marginTop:'-0.5rem'*/}
        {/*  }} >*/}
        {/*    {t('kuwait')}*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/* <Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>
            <img src={qatar} onClick={() => handleClick('Qatar', '+974', qatar)} />
            <Typography component={'p'} variant={'subtitle1'}>
              {t('qatar')}
            </Typography>
          </Grid> */}
        {/*<Grid className={classes.countryItems} item xs={12} sm={6} md={4} lg={4} xl={4}>*/}
        {/*    <img */}
        {/*      src={bahrain}*/}
        {/*      style={{ height: '8.8rem' }}*/}
        {/*      onClick={() => handleClick('Bahrain', '+973', bahrain)} */}
        {/*    />*/}
        {/*    <Typography component={'p'} variant={'subtitle1'} style={{*/}
        {/*      marginTop:'-0.45rem'*/}
        {/*    }} >*/}
        {/*      {t('bahrain')}*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
      </Grid>
    </div>
  );
};
